import { Header, Main, Footer } from './components'

export default function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
